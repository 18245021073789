import styled from 'styled-components';

export const HomePageStyle = styled.section`
  background: var(--dark100);
  height: 100vh;
  /* height: calc(100vh - var(--headerHeight)); */
  color: #fff;
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 24px;
    background-size: 100%;

    > :last-child {
      display: none;
    }
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-end;
  padding: 48px 48px 60px;
  max-width: 540px;
  min-width: 540px;
  max-height: 100svh;

  .image-cont {
    padding: 0;
  }

  h1 {
    color: #fff;
  }

  p {
    font-size: 18px;
    line-height: 26px;
    color: var(--gray200);

    &:not(:last-of-type) {
      padding-bottom: 1ch;
    }
  }

  .btn-text {
    margin-top: 20px;
    width: 100%;
  }

  .mobile-image {
    display: none;
  }

  h1 {
    font-size: 48px !important;
    line-height: 52px !important;
    letter-spacing: 1.6px !important;
  }

  @media (max-height: 800px) {
    h1 {
      font-size: 32px !important;
      line-height: 120% !important;
    }

    p {
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media (max-height: 700px) {
    .desk-image {
      display: none;
    }
  }

  @media (max-width: 800px) {
    min-width: unset;
    max-width: unset;
    padding: 0 16px 16px 16px;
    height: 100%;
    gap: 16px;

    h1 {
      font-size: 28px !important;
      font-weight: 400 !important;
      line-height: 120% !important;
      margin-bottom: -4px !important;
    }

    .btn-text {
      margin-top: 8px;
    }

    .mobile-image {
      display: block;
      width: calc(100% + 32px);
      height: 100%;
      margin-left: -16px;
      margin-right: -16px;
    }
  }
`;
