'use client';
import { useEffect, useMemo, useState } from 'react';

//* Style
import { HomePageStyle, Col } from '@/styles/pages/HomePageStyle';

//* Components
import Page from '@/components/global/Page';
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import Image from '@/components/global/Image';
import { HX } from '@/components/global/HX';
import { useResizeObserver, useTranslation } from '@/helpers';

export default function HomeTemplate({}) {
  const t = useTranslation('homePage');
  const width = useResizeObserver();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const isMobile = useMemo(() => {
    if (!isClient) return false;
    return matchMedia('(max-width: 500px)').matches;
  }, [width, isClient]);

  const isTablet = useMemo(() => {
    if (!isClient) return false;
    return matchMedia('(max-width: 800px) and (min-width:500px)').matches;
  }, [width, isClient]);

  return (
    <Page className={`home`}>
      <HomePageStyle>
        <Col>
          {isMobile && (
            <Image
              className="mobile-image"
              src="/images/home/hero-mobile.webp"
            />
          )}
          {isTablet && (
            <Image className="mobile-image" src="/images/home/hero.webp" />
          )}
          {!isMobile && !isTablet && (
            <Image
              className="desk-image"
              src="/images/svg/circle-icon.svg"
              width={64}
              height={64}
            />
          )}
          <HX text={'homePage.title'} />
          <div>
            <Text text={'homePage.description1'} />
            <Text text={'homePage.description2'} />
            <Text text={'homePage.description3'} />
          </div>
          <Button btnType={'link'} url={'/legend'} variant={'secondary'}>
            {t('goToLegend')}
          </Button>
        </Col>
        <Image src={'/images/home/hero.webp'} />
      </HomePageStyle>
    </Page>
  );
}
